import { useState } from "react"
import FormErrors from "../components/FormErrors"
import { requestPasswordReset } from "../utils/allauth"
import { Link } from "react-router-dom"
import PageHeaderModal from "./PageHeaderModal"

const initialResponse = { fetching: false, content: null }
export default function RequestPasswordReset() {
  const [email, setEmail] = useState("")
  const [response, setResponse] = useState(initialResponse)

  function submit() {
    setResponse({ ...response, fetching: true })
    requestPasswordReset(email)
      .then((content) => {
        setResponse((r) => {
          return { ...r, content }
        })
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }

  function resetResponse() {
    setResponse(initialResponse)
    return true
  }

  if (response.content?.status === 200) {
    return (
      <>
        <p className=" text-center mb-3">
          Password reset email sent.{" "}
          <Link className="link-underline-light-custom link-offset-1" to="/account/login">
            Sign in
          </Link>
        </p>

        <p className="small text-center m-0">
          Didn't receive the email?{" "}
          <Link
            onClick={resetResponse}
            className="link-underline-light-custom link-offset-1"
            to="/account/password/reset"
          >
            Try resetting password again
          </Link>
        </p>
      </>
    )
  }
  return (
    <>
      <PageHeaderModal>Reset your password</PageHeaderModal>
      <FormErrors errors={response.content?.errors} />

      <form>
        <div className="mb-3">
          <label htmlFor="resetPasswordEmail" className="form-label small px-2">
            Email address
          </label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            className="form-control rounded-3"
            id="resetPasswordEmail"
            aria-describedby="emailHelp"
            required
          />
          <FormErrors param="email" errors={response.content?.errors} />
        </div>

        <div className="d-grid gap-2 mb-3">
          <button
            disabled={!email || response.fetching}
            className="btn btn-primary rounded-3"
            type="submit"
            onClick={() => submit()}
          >
            Reset password
          </button>
        </div>
      </form>

      <p className="small text-center m-0">
        Remember your password?{" "}
        <Link className="link-underline-light-custom link-offset-1" to="/account/login">
          Sign in
        </Link>
      </p>
    </>
  )
}
