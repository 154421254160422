import { Link } from "react-router-dom"
import MainWrap from "../components/MainWrap"

export default function VerificationEmailSent() {
  return (
    <MainWrap
      invisible
      NoticePanel={
        <>
          Back to <Link to="/settings">Account Settings</Link>
        </>
      }
    >
      <p>A verification email has just been sent. Please, check your inbox.</p>
    </MainWrap>
  )
}
