import { ReactComponent as IconDateno } from "../assets/icons/IconDateno.svg"
import Footer from "./Footer"
import HeaderNav from "./HeaderNav"

const AnonLayout = ({ title, children }) => {
  return (
    <>
      <div className="anon-bg d-flex flex-column h-100">
        <div className="mx-auto">
          <HeaderNav />
        </div>
        <main
          className="bg-body-custom form-signin w-100 m-auto rounded-4 shadow p-4 py-6"
          style={{ maxWidth: "400px" }}
        >
          <div className="d-flex justify-content-center  align-items-center mb-4">
            <IconDateno className="anon-logo" />
            <div className="fs-2 mx-3">Dateno</div>
          </div>
          {children}
        </main>
        {/* <div className="mt-auto">
          <shared-footer></shared-footer>
        </div> */}
        <Footer anon={true} />
      </div>
    </>
  )
}

export default AnonLayout
