import MockSubscribe from "./MockSubscribe"
import Subscribe from "./Subscribe"
import MainWrap from "../components/MainWrap"

const TEST_MODE = true
const SubscribeWidget = TEST_MODE ? MockSubscribe : Subscribe

export default function Checkout() {
  return (
    <MainWrap invisible>
      <div className="description">
        <p>
          Subscribe to get access to the additional paid services for working with data required by professional users,
          such as an extended API and advanced capabilities for working with search results.
        </p>
        <p>
          By joining now, you’ll be the first to know when subscriptions become available. Tell us, if you want to be
          enrolled as a beta tester.
        </p>
      </div>

      <SubscribeWidget />
    </MainWrap>
  )
}
