import { Link } from "react-router-dom"
import { WEB_SEARCH_URLS } from "../utils/helpers"

const HeaderNav = () => (
  <nav className="flex py-3 flex-row content-center items-center gap-2">
    <Link to={WEB_SEARCH_URLS.about} className="p-2 pl-4 xtext-black dark:text-white text-decoration-none header-link">
      About
    </Link>
    <Link to={WEB_SEARCH_URLS.search} className="p-2 xtext-black dark:text-white text-decoration-none header-link">
      Search
    </Link>
    {/* <Link to={WEB_SEARCH_URLS.pricing} className="p-2 xtext-black dark:text-white text-decoration-none header-link">
      Pricing
    </Link> */}
    <Link to={WEB_SEARCH_URLS.apiacess} className="p-2 xtext-black dark:text-white text-decoration-none header-link">
      API Access
    </Link>
    <Link to="/feedback" className="p-2 xtext-black dark:text-white text-decoration-none header-link">
      Share Feedback
    </Link>
  </nav>
)

export const ErrorHeaderNav = () => (
  <nav className="flex py-3 flex-row content-center items-center gap-2">
    <a href={WEB_SEARCH_URLS.about} className="p-2 pl-4 xtext-black dark:text-white text-decoration-none header-link">
      About
    </a>
    <a href={WEB_SEARCH_URLS.search} className="p-2 xtext-black dark:text-white text-decoration-none header-link">
      Search
    </a>
    <a href={WEB_SEARCH_URLS.apiacess} className="p-2 xtext-black dark:text-white text-decoration-none header-link">
      API Access
    </a>
  </nav>
)

export default HeaderNav
