import { useState } from "react"
import { useLoaderData, Navigate, Link } from "react-router-dom"
import { getEmailVerification, verifyEmail } from "../utils/allauth"
import MainWrap from "../components/MainWrap"
import Button from "../components/Button"

export async function loader({ params }) {
  const key = params.key
  const resp = await getEmailVerification(key)
  return { key, verification: resp }
}

export default function VerifyEmail() {
  const { key, verification } = useLoaderData()
  const [response, setResponse] = useState({ fetching: false, content: null })
  function submit() {
    setResponse({ ...response, fetching: true })
    verifyEmail(key)
      .then((content) => {
        setResponse((r) => {
          return { ...r, content }
        })
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }

  if ([200, 401].includes(response.content?.status)) {
    return <Navigate to="/" />
  }

  let body = null
  if (verification.status === 200) {
    body = (
      <>
        <p>
          Please confirm that <a href={"mailto:" + verification.data.email}>{verification.data.email}</a> is an email
          address for user {verification.data.user.display}.
        </p>
        <Button disabled={response.fetching} onClick={() => submit()}>
          Confirm to verify
        </Button>
      </>
    )
  } else if (!verification.data?.email) {
    body = (
      <p>
        Invalid verification link. <Link to="/account/email">Manage connected emails</Link>.
      </p>
    )
  } else {
    body = (
      <p>
        Unable to confirm email <a href={"mailto:" + verification.data.email}>{verification.data.email}</a> because it
        is already confirmed. <Link to="/account/email">Manage connected emails</Link>.
      </p>
    )
  }
  return <MainWrap invisible>{body}</MainWrap>
}
