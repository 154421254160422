import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useUser } from "./auth"
import { getPrimaryKey } from "./utils/userapi"
import { getSearchUrl, getApiDocsUrl } from "./utils/helpers"
import { CardWrap } from "./components/MainWrap"
import ApiKey from "./components/ApiKey"

export default function Home() {
  const user = useUser()
  const [primaryKey, setPrimaryKey] = useState("...")

  // triggers once on a compoment mount
  useEffect(() => {
    getPrimaryKey()
      .then((response) => {
        if (response.primary_key) {
          setPrimaryKey(response.primary_key)
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the user details!", error)
      })
  }, [])

  return (
    <div className="container  m-0 p-0 py-3" style={{ maxWidth: "600px" }}>
      <p className="text-body-tertiary fs-08 m-0 px-3 py-1">{user.email}</p>
      <div className="row m-0 p-0">
        <CardWrap>
          <h3 className="fw-light fs-4 px-2 py-1">Your API key</h3>
          <p className="p-2">
            This is your API key. Checkout{" "}
            <a href={getApiDocsUrl()} target="_blank" rel="noreferrer">
              Dateno API docs
            </a>{" "}
            for more info.
          </p>
          <ApiKey apikey={primaryKey} />
        </CardWrap>
      </div>
      <div className="row  m-0 p-0">
        <CardWrap>
          <h3 className="fw-light fs-4 px-2 py-1">Search for datasets</h3>

          <p className="p-2"> Fast, comprehensive access to open datasets worldwide</p>
          <div className="d-grid gap-2">
            <a href={getSearchUrl()} className="btn btn-primary" role="button">
              Start browsing
            </a>
          </div>
        </CardWrap>

        <CardWrap>
          <h3 className="fw-light fs-4 px-2 py-1">Subscribe</h3>
          <p className="p-2">Get Early Access to Dateno API features.</p>
          <div className="d-grid gap-2">
            <Link to="/checkout" className="btn btn-primary" role="button">
              Get Early Access
            </Link>
          </div>
        </CardWrap>
      </div>
    </div>
  )
}
