import { useConfig } from "../auth"
import { redirectToProvider } from "../utils/allauth"
// import GoogleOneTap from './GoogleOneTap'
import { ReactComponent as IconGithub } from "../assets/icons/IconGithub.svg"
import { ReactComponent as IconGoogle } from "../assets/icons/IconGoogle.svg"

const SOCIAL_ICONS = Object.freeze({
  github: <IconGithub height="24" width="24" />,
  google: <IconGoogle />,
})

export default function ProviderList(props) {
  const config = useConfig()
  const providers = config.data.socialaccount.providers
  const label = props.signup ? "Sign up" : "Sign in"
  if (!providers.length) {
    return null
  }
  return (
    <>
      {/* <GoogleOneTap process={props.process} /> */}
      <div className="d-grid gap-3">
        {providers.map((provider) => {
          return (
            <button
              key={provider.id}
              className="btn btn-outline-light text-black fw-medium border rounded-12"
              type="button"
              onClick={() => redirectToProvider(provider.id, props.callbackURL, props.process)}
            >
              {SOCIAL_ICONS[provider.id]}
              {"  "}
              {label} with {provider.name}
            </button>
          )
        })}
      </div>
    </>
  )
}
