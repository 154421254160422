import { useLocation, Link } from "react-router-dom"

export default function NavBarItem(props) {
  const location = useLocation()
  const isActive =
    (props.href && location.pathname.startsWith(props.href)) ||
    (props.to !== "/" && props.to && location.pathname.startsWith(props.to)) ||
    (props.to === "/" && location.pathname === "/")
  const cls = isActive ? "nav-link d-flex align-items-center gap-2 active" : "nav-link  d-flex align-items-center gap-2"
  const label = props.icon ? `${props.icon} ${props.name}` : props.name

  
  function handleClick() {
    // closes offcanvas navbar on small scrrens
    let closeCanvas = document.querySelector('.show [data-bs-dismiss="offcanvas"]')

    if (closeCanvas) {
      closeCanvas.click()
    }
    
    return true
  }

  return (
    <li className="nav-item">
      {props.href ? (
        <a className={cls} href={props.href}>
          {label}
        </a>
      ) : (
        <Link className={cls} to={props.to} onClick={handleClick}>
          {label}
        </Link>
      )}
    </li>
  )
}
