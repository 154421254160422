import Feedback from "./Feedback"
import MainWrap from "../components/MainWrap"
import { useUser } from "../auth"

export default function Checkout() {
  const user = useUser()
  return (
    <MainWrap NoticePanel={user.email}>
      <Feedback />
    </MainWrap>
  )
}
