import { useConfig } from "../auth"
import NavBarItem from "./NavBarItem"
// import { getSearchUrl, getApiDocsUrl } from "../utils/helpers"

function AccountSettings() {
  const config = useConfig()

  return (
    <>
      <ul className="nav flex-column">
        <NavBarItem to="/account/password/change" name="Change Password" />
        {/* <NavBarItem to="/account/email" name="Connected Emails" /> */}
        {/* {config.data.socialaccount ? (<NavBarItem to="/account/providers" name="Social Providers"/>) : null} */}
        {config.data.usersessions ? <NavBarItem to="/account/sessions" name="Your Sessions" /> : null}
        <NavBarItem to="/account/logout" name="Sign out" />
      </ul>
    </>
  )
}

export default function NavBar() {
  return (
    <>
      <ul className="nav flex-column">
        <NavBarItem to="/" name="Dashboard" />
        <NavBarItem to="/subscribe" name="Subscriptions" />
        {/* <NavBarItem href={getSearchUrl()} name="Dataset Search" /> */}
        <NavBarItem to="/history" name="Search History" />
        {/* <NavBarItem href={getApiDocsUrl()} name="API Docs" /> */}
        <NavBarItem to="/feedback" name="Share Feedback" />
      </ul>

      <hr className="my-3" />

      <ul className="nav flex-column mb-4">
        <NavBarItem to="/settings" name="Account settings" more={<AccountSettings />} />
      </ul>
    </>
  )
}
