import { useState } from "react"
import classes from "./ApiKey.module.css"

export default function ApiKey({ apikey }) {
  const [hint, setHint] = useState("")

  const copyKey = () => {
    navigator.clipboard.writeText(apikey)
    setHint("Just copied!")
    setTimeout(() => {
      setHint("")
    }, 500)
  }

  return (
    <p className="pe-auto text-bg-light p-2 rounded-3 border" onClick={copyKey}>
      <samp title="Click to copy" className={classes["blurry"]} style={{ cursor: "pointer" }}>
        {apikey}
      </samp>
      {hint && <span className="px-2 text-body-tertiary">{hint}</span>}
    </p>
  )
}
