import { useState } from "react"
import FormErrors from "../components/FormErrors"
import { getPasswordReset, resetPassword } from "../utils/allauth"
import { Navigate, Link, useLoaderData } from "react-router-dom"
import Button from "../components/Button"
import PageHeaderModal from "./PageHeaderModal"

export async function loader({ params }) {
  const key = params.key
  const resp = await getPasswordReset(key)
  return { key, keyResponse: resp }
}

export default function ResetPassword() {
  const { key, keyResponse } = useLoaderData()

  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [password2Errors, setPassword2Errors] = useState([])

  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit() {
    if (password2 !== password1) {
      setPassword2Errors([{ param: "password2", message: "Password does not match." }])
      return
    }
    setPassword2Errors([])
    setResponse({ ...response, fetching: true })
    resetPassword({ key, password: password1 })
      .then((resp) => {
        setResponse((r) => {
          return { ...r, content: resp }
        })
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }
  if ([200, 401].includes(response.content?.status)) {
    return <Navigate to="/account/login" />
  }
  let body
  if (keyResponse.status !== 200) {
    body = (
      <>
        <FormErrors param="key" errors={keyResponse.errors} />
        <p>
          Request password reset <Link to="/account//account/password/rese">again</Link>
        </p>
      </>
    )
  } else if (response.content?.error?.detail?.key) {
    body = (
      <>
        <FormErrors param="key" errors={response.content?.errors} />
        <p>
          Request password reset <Link to="/account//account/password/rese">again</Link>
        </p>
      </>
    )
  } else {
    body = (
      <>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label  small px-2">
            Password
          </label>
          <input
            autoComplete="new-password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            required
          />
          <FormErrors param="password" errors={response.content?.errors} />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label  small px-2">
            Password (again){" "}
          </label>
          <input
            autoComplete="new-password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            type="password"
            className="form-control"
            id="exampleInputPassword2"
            required
          />
          <FormErrors param="password2" errors={password2Errors} />
        </div>
        <div className="d-grid gap-2 mb-3">

        <Button disabled={response.fetching} onClick={() => submit()}>
          Reset password
        </Button>
        </div>
        </>
    )
  }

  return (
    <>
      <PageHeaderModal>Reset your password</PageHeaderModal>
      <p className="small text-center m-0">
        Remember your password? <Link className="link-underline-light-custom link-offset-1" to="/account/login">Back to sign in</Link>
      </p>
      {body}
    </>
  )
}
