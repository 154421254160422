import { useState, useEffect } from "react"
import { getPlans } from "../utils/restapi"
import PlanCard from "./PlanCard"

const ActivePlans = ({ onSubscriptionsUpdate }) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchPlans()
  }, [])

  const fetchPlans = async () => {
    try {
      setError("")
      setLoading(true)
      const response = await getPlans()
      setResponse(response)
      setLoading(false)
    } catch (error) {
      console.log("Failed to fetch plans", error)

      setError("Failed to fetch plans")
      setLoading(false)
    }
  }

  if (loading) {
    return <p>Loading available plans</p>
  }

  return (
    <>
      {response.count ? (
        <div className="row m-0 p-0">
          {response.results?.map((plan) => (
            <PlanCard key={plan?.id} plan={plan} onSubscriptionCreate={onSubscriptionsUpdate} />
          ))}
        </div>
      ) : (
        <p className="row m-0 p-0">No plans yet. Ask admins to setup the plans.</p>
      )}
      {error ? <p>Error {JSON.stringify(error)}</p> : ""}
    </>
  )
}

export default ActivePlans
