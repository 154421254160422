import Settings from "./settings"
import qs from "qs"

export function getPublicPageUrl(name) {
  if (!name) {
    return Settings.DATENO_FREE_SEARCH_URL
  }
  return `${Settings.DATENO_FREE_SEARCH_URL}/${name}/`
}

export function getSearchUrl(queryString) {
  const params = queryString ? "?" + queryString : ""
  return getPublicPageUrl("search") + params
}

export function getApiDocsUrl() {
  return Settings.DATENO_PUBLIC_API_URL
}

export const SOCIAL_LINKS = Object.freeze({
  github: "https://github.com/datenoio",
  twitter: "https://x.com/datenoproject",
  telegram: "https://t.me/datenosearch",
  discord: "https://discord.gg/tydNfp5EY8",
  medium: "https://medium.com/dateno/about",
})

export function queryToObject(query) {
  // Must use the same parser as in instantsearch getRouting (see dateno-frontend)
  return qs.parse(query)
}

export const WEB_SEARCH_URLS = Object.freeze({
  home: `${Settings.DATENO_FREE_SEARCH_URL}`,
  search: `${Settings.DATENO_FREE_SEARCH_URL}/search`,
  help: `${Settings.DATENO_FREE_SEARCH_URL}/help`,
  about: `${Settings.DATENO_FREE_SEARCH_URL}/about`,
  apiacess: `${Settings.DATENO_FREE_SEARCH_URL}/apiaccess`,
  pricing: `${Settings.DATENO_FREE_SEARCH_URL}/pricing`,
  registry: `${Settings.DATENO_FREE_SEARCH_URL}/registry`,
  conditions: `${Settings.DATENO_FREE_SEARCH_URL}/conditions`,
  privacy: `${Settings.DATENO_FREE_SEARCH_URL}/privacy`,
})
