import { useState, useEffect } from "react"
import { getProviderAccounts, disconnectProviderAccount, AuthProcess } from "../utils/allauth"
import ProviderList from "./ProviderList"
import FormErrors from "../components/FormErrors"
import { useUser } from "../auth"
import MainWrap from "../components/MainWrap"

export default function ManageProviders() {
  const user = useUser()
  const [accounts, setAccounts] = useState([])
  const [response, setResponse] = useState({ fetching: false, content: { status: 200, data: [] } })

  useEffect(() => {
    setResponse((r) => {
      return { ...r, fetching: true }
    })
    getProviderAccounts()
      .then((resp) => {
        if (resp.status === 200) {
          setAccounts(resp.data)
        }
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }, [])

  function disconnect(account) {
    setResponse({ ...response, fetching: true })
    disconnectProviderAccount(account.provider.id, account.uid)
      .then((resp) => {
        setResponse((r) => {
          return { ...r, content: resp }
        })
        if (resp.status === 200) {
          setAccounts(resp.data)
        }
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }

  return (
    <MainWrap NoticePanel={user.email}>
      <table className="table">
        <thead>
          <tr>
            <th>UID</th>
            <th>Account</th>
            <th>Provider</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account) => {
            return (
              <tr key={account.uid}>
                <td>{account.uid}</td>
                <td>{account.display}</td>
                <td>{account.provider.name}</td>
                <td>
                  <button className="btn btn-light" onClick={() => disconnect(account)} disabled={response.fetching}>
                    Disconnect
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <FormErrors errors={response.content?.errors} />

      <h3 className="fs-4 fw-light mt-5 mb-3">Connect more providers</h3>
      <div style={{ width: "350px" }}>
        <ProviderList callbackURL="/account/providers" process={AuthProcess.CONNECT} />
      </div>
    </MainWrap>
  )
}
