import { useState } from "react"
import { cancelSubscription } from "../utils/restapi"
import InvoiceDetails from "./InvoiceDetails"
import { CardWrap } from "../components/MainWrap"

const CurrentSubscriptions = ({ subscriptions, onSubscriptionsUpdate }) => {
  const [error, setError] = useState(null)

  const handleCancelSubscription = async (item) => {
    try {
      await cancelSubscription(item.id)
      await onSubscriptionsUpdate()
    } catch (err) {
      setError(err)
    }
  }

  return (
    <>
      <div className="m-0 p-0 py-1" style={{ maxWidth: "600px" }}>
        {subscriptions.results?.map((item) => (
          <CardWrap key={item.id}>
            <h3 className="fw-light fs-4 px-2 py-1">{item.plan.name}</h3>
            {item.status !== "completed" ? <p className="text-body-tertiary m-0 p-2">{item.status_str}</p> : ""}
            <p className="m-0 p-2">Subscription was created on {new Date(item.created).toLocaleDateString()}.</p>
            {item.invoices?.map((inv) => (
              <InvoiceDetails key={inv.bitcart_invoice_id} invoice={inv} />
            ))}
            {item.status === "pending" && (
              <button className="btn btn-light p-2 border" onClick={() => handleCancelSubscription(item)}>
                Cancel subscription
              </button>
            )}
          </CardWrap>
        ))}
      </div>
      {error && <p>Failed to cancel the subscription. {JSON.stringify(error)}</p>}
    </>
  )
}

export default CurrentSubscriptions
