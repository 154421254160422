import { useState, useEffect } from "react"
import * as allauth from "../utils/allauth"
import FormErrors from "../components/FormErrors"
import { Link } from "react-router-dom"
import MainWrap from "../components/MainWrap"

export default function ChangeEmail() {
  const [email, setEmail] = useState("")
  const [verificationEmailStatus, setVerificationEmailStatus] = useState("")
  const [emailAddresses, setEmailAddresses] = useState([])
  const [response, setResponse] = useState({ fetching: false, content: { status: 200, data: [] } })

  useEffect(() => {
    setResponse((r) => {
      return { ...r, fetching: true }
    })
    allauth
      .getEmailAddresses()
      .then((resp) => {
        if (resp.status === 200) {
          setEmailAddresses(resp.data)
        }
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }, [])

  function addEmail() {
    setResponse({ ...response, fetching: true })
    allauth
      .addEmail(email)
      .then((resp) => {
        setResponse((r) => {
          return { ...r, content: resp }
        })
        if (resp.status === 200) {
          setEmailAddresses(resp.data)
          setEmail("")
        }
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }

  function requestEmailVerification(email) {
    setVerificationEmailStatus("")
    setResponse({ ...response, fetching: true })
    allauth
      .requestEmailVerification(email)
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
        setVerificationEmailStatus("A verification email has just been sent. Please, check your inbox.")
      })
  }

  function deleteEmail(email) {
    setResponse({ ...response, fetching: true })
    allauth
      .deleteEmail(email)
      .then((resp) => {
        setResponse((r) => {
          return { ...r, content: resp }
        })
        if (resp.status === 200) {
          setEmailAddresses(resp.data)
        }
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }

  function markAsPrimary(email) {
    setResponse({ ...response, fetching: true })
    allauth
      .markEmailAsPrimary(email)
      .then((resp) => {
        setResponse((r) => {
          return { ...r, content: resp }
        })
        if (resp.status === 200) {
          setEmailAddresses(resp.data)
        }
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }

  return (
    <MainWrap
      NoticePanel={
        <>
          Back to <Link to="/settings">Account Settings</Link>
        </>
      }
    >
      <div className="table-responsive">
        {verificationEmailStatus ? (
          <div className="alert alert-success p-1">
            <p className="small p-1 m-0">{verificationEmailStatus}</p>
          </div>
        ) : null}
        <table className="table table-hover align-middle">
          <thead>
            <tr>
              <th>Email</th>
              <th className="text-center">Verified</th>
              <th className="text-center">Primary</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {emailAddresses.map((ea) => {
              return (
                <tr key={ea.email}>
                  <td>{ea.email}</td>
                  <td className="text-center">{ea.verified ? "✅" : "❌"}</td>
                  <td className="text-center">
                    <input onChange={() => markAsPrimary(ea.email)} type="radio" checked={ea.primary} />
                  </td>
                  <td>
                    {ea.verified ? (
                      ""
                    ) : (
                      <button
                        className="btn"
                        onClick={() => requestEmailVerification(ea.email)}
                        disabled={response.fetching}
                      >
                        Resend verification email
                      </button>
                    )}
                    {ea.primary ? (
                      ""
                    ) : (
                      <button className="btn" onClick={() => deleteEmail(ea.email)} disabled={response.fetching}>
                        Remove
                      </button>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <FormErrors errors={response.content.errors} />

      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          New email address
        </label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-control"
          type="email"
          required
        />
        <FormErrors param="email" errors={response.content?.errors} />
      </div>
      <button className="btn btn-primary" disabled={response.fetching || !email} onClick={() => addEmail()}>
        Add email address
      </button>
    </MainWrap>
  )
}
