import { useTitle } from "./useTitle"
import { useUser } from "../auth"

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function SetTitle({ title, fnTitle, children }) {
  const user = useUser()
  let pageTitle = title

  if (fnTitle) {
    pageTitle = fnTitle(capitalizeFirstLetter(user.display))
  }
  useTitle(pageTitle)
  
  return children
}
