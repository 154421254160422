import { useConfig } from "../auth"
import SettingItem from "../components/SettingItem"

export default function AccountSettings() {
  const config = useConfig()

  return (
    <ul className="list-group shadow m-0" style={{ maxWidth: "600px" }}>
      <SettingItem to="/account/email" name="Manage connected emails" />
      <SettingItem to="/account/password/change" name="Change password" />
      {config.data.socialaccount ? (
        <SettingItem to="/account/providers" name="Manage social auth providers"></SettingItem>
      ) : null}
      {config.data.usersessions ? (
        <SettingItem to="/account/sessions" name="Your sessions at Dateno"></SettingItem>
      ) : null}
      <SettingItem to="/account/logout" name="Sign out" />
    </ul>
  )
}
