import { useEffect, createContext, useState } from "react"
import { getAuth, getConfig } from "../utils/allauth"
import Footer from "../components/Footer"
import { ErrorHeaderNav } from "../components/HeaderNav"

export const AuthContext = createContext(null)

function Loading() {
  return (
    <div className="d-flex m-5 justify-content-center">
      <div className="spinner-border text-secondary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}

function LoadingError({ config }) {
  return (
    <div className="anon-bg d-flex flex-column h-100">
      <div className="mx-auto">
        <ErrorHeaderNav />
      </div>
      <main className="bg-body-custom form-signin w-100 m-auto rounded-4 shadow p-4 py-6" style={{ maxWidth: "800px" }}>
        <h1 className="fs-3 w-light my-3">Oops! Something Went Wrong</h1>
        <p>It seems like we’re having trouble connecting to the server right now.</p>
        <h4 className="w-light my-2">🚧 Error Code: 502 - Bad Gateway</h4>
        <p>Our team is already on it, and we’re working to get things back up and running as quickly as possible.</p>
        <p>In the meantime, here’s what you can try:</p>
        <ul>
          <li>Refresh the page in a few minutes.</li>
          <li>Check your internet connection (just in case).</li>
        </ul>
        <p>
          We appreciate your patience! 🙏
          <br />
          If this issue persists, feel free to contact us at <a href="mailto:support@dateno.io">support@dateno.io</a>.
        </p>
      </main>
      <Footer anon={true} />
    </div>
  )
}

export function AuthContextProvider(props) {
  const [auth, setAuth] = useState(undefined)
  const [config, setConfig] = useState(undefined)

  useEffect(() => {
    function onAuthChanged(e) {
      setAuth((auth) => {
        if (typeof auth === "undefined") {
          // console.log("Authentication status loaded")
        } else {
          // console.log("Authentication status updated")
        }
        return e.detail
      })
    }

    document.addEventListener("allauth.auth.change", onAuthChanged)
    getAuth()
      .then((data) => {
        setAuth(data)
        // setAuth(false) // debug
      })
      .catch((e) => {
        setAuth(false)
      })
    getConfig()
      .then((data) => setConfig(data))
      .catch((e) => {
        setConfig({ status: "502 Bad Gateway", message: e.message, e })
      })
    return () => {
      document.removeEventListener("allauth.auth.change", onAuthChanged)
    }
  }, [])

  const loading = typeof auth === "undefined" || typeof config === "undefined"
  // It will show Loading Error if getAuth cathes error (like 5xx http status error)
  // The loadingError coponent will show current config even if get Config catches 5xx error too
  return (
    <AuthContext.Provider value={{ auth, config }}>
      {loading ? <Loading /> : auth === false ? <LoadingError config={config} /> : props.children}
    </AuthContext.Provider>
  )
}
