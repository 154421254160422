import Settings from "./settings"
import { getCSRFToken } from "./django"

const BASE_URL = Settings.MY_DATENO_BASE_URL // if this file is used outside user-hub this url should be set to process.env.REACT_APP_MY_DATENO_BASE_URL

export const URLs = Object.freeze({
  // search history
  SAVE_HISTORY_RECORD: BASE_URL + "/api/search/history/queries/",
  GET_HISTORY_RECORD_LIST: BASE_URL + "/api/search/history/queries/",
  DELETE_HISTORY_RECORD: BASE_URL + "/api/search/history/queries/",

  // users
  SEND_FEEDBACK: BASE_URL + "/api/users/feedback/",

  // subscriptions
  GET_PLANS: BASE_URL + "/api/subscriptions/plans/",
  CREATE_SUBSCRIPTION: BASE_URL + "/api/subscriptions/subscriptions/",
  GET_SUBSCRIPTION_LIST: BASE_URL + "/api/subscriptions/subscriptions/list/",
  GET_SUBSCRIPTIONS: BASE_URL + "/api/subscriptions/subscriptions/", // current or pending
  CANCEL_SUBSCRIPTION: (id) => `${URLs.GET_SUBSCRIPTIONS}${id}/cancel/`,

  // subscription invoices
  GET_INVOICES: BASE_URL + "/api/subscriptions/invoices/",
  GET_INVOICE_STATUS: (id) => `${URLs.GET_INVOICES}${id}/status/`,
  SYNC_INVOICE_STATUS: (id) => `${URLs.GET_INVOICES}${id}/sync/status/`,

  // Kong Keys
  CONSUMERS: BASE_URL + "/api/keys/consumers/",
  KEYS: BASE_URL + "/api/keys/keys/",
  PRIMARY_KEY: BASE_URL + "/api/keys/primary/",
})

async function request(method, path, data) {
  const options = {
    method,
    credentials: "include",
    headers: {
      "X-CSRFToken": getCSRFToken(),
    },
  }
  if (typeof data !== "undefined") {
    options.body = JSON.stringify(data)
    options.headers["Content-Type"] = "application/json"
  }
  const resp = await fetch(path, options)
  const msg = await resp.json()

  return msg
}

export async function createSearchHistoryRecord(query) {
  return await request("POST", URLs.SAVE_HISTORY_RECORD, { query })
}

export async function getSearchHistoryRecordList(nextUrl) {
  // FIXME why http is returned from backend (DRF/ViewSet issue?)
  const url = nextUrl ? nextUrl.replace("http://", "https://") : undefined
  return await request("GET", url || URLs.GET_HISTORY_RECORD_LIST)
}

export async function deleteSearchHistoryRecord(recordId) {
  return await request("DELETE", URLs.DELETE_HISTORY_RECORD + `${recordId}/`)
}

export async function sendUsersFeedback(data) {
  return await request("POST", URLs.SEND_FEEDBACK, data)
}

export async function getPlans() {
  return await request("GET", URLs.GET_PLANS)
}

export async function getPrimaryKey(data) {
  return await request("GET", URLs.PRIMARY_KEY, data)
}

export async function getConsumers() {
  return await request("GET", URLs.CONSUMERS)
}

export async function getKeys(data) {
  return await request("GET", URLs.KEYS, data)
}

export async function createSubscription(planId) {
  return await request("POST", URLs.CREATE_SUBSCRIPTION, { plan: planId })
}

export async function getSubscriptions() {
  return await request("GET", URLs.GET_SUBSCRIPTIONS)
}

export async function getSubscriptionList() {
  return await request("GET", URLs.GET_SUBSCRIPTION_LIST)
}

export async function getInvoiceStatus(id) {
  return await request("GET", URLs.GET_INVOICE_STATUS(id))
}

export async function syncInvoiceStatus(id) {
  return await request("POST", URLs.SYNC_INVOICE_STATUS(id))
}

export async function cancelSubscription(id) {
  return await request("DELETE", URLs.CANCEL_SUBSCRIPTION(id))
}
