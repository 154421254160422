// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApiKey_blurry__72Vbi {
  cursor: pointer;
  color: transparent;
  text-shadow: 0 0 0.4rem #000;
  &:hover {
    color: buttontext;
    text-shadow: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ApiKey.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,4BAA4B;EAC5B;IACE,iBAAiB;IACjB,iBAAiB;EACnB;AACF","sourcesContent":[".blurry {\n  cursor: pointer;\n  color: transparent;\n  text-shadow: 0 0 0.4rem #000;\n  &:hover {\n    color: buttontext;\n    text-shadow: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blurry": `ApiKey_blurry__72Vbi`
};
export default ___CSS_LOADER_EXPORT___;
