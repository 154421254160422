export function CardWrap({ invisible, children }) {
  let cls = "col rounded-3 m-2 p-2 "
  if (invisible) {
    // do nothing
  } else {
    cls += " dtn-card-wrap shadow"
  }
  return <div className={cls}>{children}</div>
}

export function SectionWrap({ invisible, children }) {
  return (
    <div className="row m-0 p-0">
      <CardWrap invisible={invisible}>{children}</CardWrap>
    </div>
  )
}

export default function MainWrap({ NoticePanel, width, invisible, children }) {
  const styles = {}
  if (width !== "auto") {
    styles.maxWidth = "600px"
  }
  return (
    <div className="container  m-0 p-0 py-3" style={styles}>
      {NoticePanel ? <p className="text-body-tertiary fs-08 m-0 px-3 py-1">{NoticePanel}</p> : null}
      <SectionWrap invisible={invisible}>{children}</SectionWrap>
    </div>
  )
}
