import { useUser } from "../auth"
import MainWrap from "../components/MainWrap"

export default function VerificationEmailSent() {
  const user = useUser()
  return (
    <MainWrap NoticePanel={user.email}>
      <p>A verification email has just been sent. Please, check your inbox.</p>
    </MainWrap>
  )
}
