import { useState } from "react"
import { createSubscription } from "../utils/restapi"

export default function PlanCard(props) {
  const { plan, onSubscriptionCreate } = props
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const perPeriod = { 1: "month", 12: "year" }[plan.duration] || (plan.duration ? plan.duration : "Forever")

  const isEnterprise = plan.price === 0 && plan.duration === 0

  window.bitcart?.onModalWillEnter(async () => {
    if (typeof onSubscriptionCreate === "function") {
      await onSubscriptionCreate() // update list of subscription on page or etc.
    }
  })
  // window.bitcart?.onModalWillLeave(() => {
  //   console.log("bitcart leave")
  // })
  // window.bitcart?.onModalReceiveMessage((evt) => {
  //   console.log("bitcart receive message", evt)
  //   // log GA etc
  // })
  const handleCreateSubscription = async (planId) => {
    try {
      setError(null)
      setLoading(true)
      const response = await createSubscription(planId)

      if (response.bitcart_invoice_id && window.bitcart?.showInvoice) {
        window.bitcart.showInvoice(response.bitcart_invoice_id)
      }
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }

  return (
    <div className="col rounded-3 m-2 p-2 dtn-card-wrap shadow  d-flex flex-column justify-content-between">
      <div>
        <h3 className="fw-light fs-4 px-2 py-1">{plan.name}</h3>
        <p className="p-2">{plan.description}</p>
      </div>
      <div>
        {!isEnterprise && (
          <p className="p-2">
            ${plan.price} / {perPeriod}
          </p>
        )}
        {plan.kong_rate_limit ? (
          <p className="p-2">
            Request rate limits:
            <br />
            {plan.kong_rate_limit.name}
          </p>
        ) : (
          !isEnterprise && <p className="p-2">No limits set</p>
        )}
        <div className="d-grid gap-2">
          {isEnterprise ? (
            <a className="btn btn-primary" href="mailto:sales@dateno.io">
              Contact us
            </a>
          ) : (
            <button disabled={loading} className="btn btn-primary" onClick={() => handleCreateSubscription(plan.id)}>
              {loading ? "Crafting an invoice for you" : "Subscribe"}
            </button>
          )}

          {error ? <p>Failed to create subscription. {JSON.stringify(error)}</p> : ""}
        </div>
      </div>
    </div>
  )
}
