import { WEB_SEARCH_URLS, SOCIAL_LINKS } from "../utils/helpers"
import FooterItem from "./FooterItem"

export default function Footer({ anon }) {
  return (
    <footer className="footer m-0  mt-auto mb-0 container-fluid d-flex flex-wrap justify-content-between align-items-center py-3 my-4 xborder-top">
      <ul className="nav col-md-5 justify-content-start">
        <FooterItem to="/" name="© 2025 Dateno" justtext />
        <FooterItem href={WEB_SEARCH_URLS.conditions} name="Terms" />
        <FooterItem href={WEB_SEARCH_URLS.privacy} name="Privacy" />
        <FooterItem href={SOCIAL_LINKS.github} social="github" />
        <FooterItem href={SOCIAL_LINKS.discord} social="discord" />
        <FooterItem href={SOCIAL_LINKS.twitter} social="twitter" />
      </ul>

      <ul className="nav col-md-5 justify-content-end">
        <FooterItem href={WEB_SEARCH_URLS.home} name="Home" />
        <FooterItem href={WEB_SEARCH_URLS.apiaccess} name="API Access" />
        {anon ? null : <FooterItem href={WEB_SEARCH_URLS.about} name="About" />}
        <FooterItem href={WEB_SEARCH_URLS.help} name="Help" />
        {anon ? null : <FooterItem to="/" name="My Account" />}
        <FooterItem href={WEB_SEARCH_URLS.registry} name="Registry" />
      </ul>
    </footer>
  )
}
