import { useState } from "react"
import FormErrors from "../components/FormErrors"
import { login } from "../utils/allauth"
import { Link } from "react-router-dom"
import { useConfig } from "../auth"
import ProviderList from "../socialaccount/ProviderList"
import PageHeaderModal from "./PageHeaderModal"

export default function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [response, setResponse] = useState({ fetching: false, content: null })
  const config = useConfig()
  const hasProviders = config.data.socialaccount?.providers?.length > 0

  function submit() {
    setResponse({ ...response, fetching: true })
    login({ email, password })
      .then((content) => {
        setResponse((r) => {
          return { ...r, content }
        })
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }
  return (
    <>
      <PageHeaderModal>Sign in to Dateno</PageHeaderModal>
      {hasProviders ? (
        <>
          <div className="text-center">
            <ProviderList callbackURL="/account/provider/callback" />
          </div>
          <div className="text-center text-body-tertiary small p-2">or sign in with email</div>
        </>
      ) : null}
      <FormErrors errors={response.content?.errors} />

      <form>
        <div className="mb-3">
          <label htmlFor="loginEmail" className="form-label small px-2">
            Email address
          </label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            className="form-control rounded-3"
            id="loginEmail"
            aria-describedby="emailHelp"
            required
          />
          <FormErrors param="email" errors={response.content?.errors} />
        </div>
        <div className="mb-3">
          <div className="d-flex justify-content-between  align-items-center  px-2">
            <label htmlFor="loginPassword" className="form-label small">
              Password
            </label>
            <Link className="small link-underline-light-custom link-offset-1" to="/account/password/reset">
              Forgot password?
            </Link>
          </div>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className="form-control rounded-3"
            id="loginPassword"
            required
          />
          <FormErrors param="password" errors={response.content?.errors} />
        </div>
        <div className="d-grid gap-2 mb-3">
          <button
            disabled={!email || !password || response.fetching}
            className="btn btn-primary rounded-3"
            type="submit"
            onClick={() => submit()}
          >
            Sign in
          </button>
        </div>
      </form>

      <p className="small text-center m-0">
        Don't have an account?{" "}
        <Link className="link-underline-light-custom link-offset-1" to="/account/signup">
          Sign up
        </Link>
      </p>
    </>
  )
}
