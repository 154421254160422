import { useState } from "react"
import { Navigate } from "react-router-dom"
import { useUser } from "../auth"
import { logout } from "../utils/allauth"
import MainWrap from "../components/MainWrap"

export default function Logout() {
  const [response, setResponse] = useState({ fetching: false, content: null })
  const user = useUser()

  function submit() {
    setResponse({ ...response, fetching: true })
    logout()
      .then((content) => {
        setResponse((r) => {
          return { ...r, content }
        })
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }
  if (response.content) {
    return <Navigate to="/" />
  }

  return (
    <MainWrap NoticePanel={user.email}>
      <p className="p-2 mb-0">Are you sure you want to sign out?</p>

      <button className="btn btn-primary m-2" disabled={response.fetching} onClick={() => submit()}>
        Sign out
      </button>
    </MainWrap>
  )
}
