import { useState } from "react"
import FeedbackModal from "./FeedbackModal"
import { getSocialUrl } from "../utils/helpers"

const SocLink = ({ app }) => {
  return (
    <a
      title={app}
      href={getSocialUrl(app)}
      target="_blank"
      rel="noreferrer"
      className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
    >
      {app}
    </a>
  )
}
const MockSubscribe = () => {
  const [showModal, setShowModal] = useState(false)
  const [feedbackSent, setFeedbackSent] = useState(false)
  const [feedbackFailed, setFeedbackFailed] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const handleFeedbackSuccess = () => {
    setFeedbackSent(true) // Set success message state
  }
  const handleFeedbackError = (msg) => {
    setFeedbackFailed(msg) // Set success message state
  }

  if (feedbackSent) {
    return (
      <div className="alert alert-success mt-3" role="alert">
        <p>Thank you for your interest!</p>
        <p className="text-secondary my-3 small">
          Stay connected and keep in the loop with all the latest news! Follow us for updates on{" "}
          <SocLink app="Telegram" />, <SocLink app="Medium" />, <SocLink app="Discord" />, <SocLink app="Twitter" />,
          and <SocLink app="GitHub" />.
        </p>
      </div>
    )
  }
  if (feedbackFailed) {
    return (
      <div className="alert alert-error mt-3" role="alert">
        {feedbackFailed || "Sorry. Something didn't go right."}
      </div>
    )
  }
  return (
    <>
      <div className="">
        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#feedbackModal">
          Subscribe
        </button>
      </div>
      <FeedbackModal
        show={showModal}
        handleClose={handleCloseModal}
        onFeedbackSuccess={handleFeedbackSuccess}
        onFeedbackError={handleFeedbackError}
      />
    </>
  )
}

export default MockSubscribe
