const API_URL = process.env.REACT_APP_API_URL || "https://api.dateno.io"
const DATENO_FREE_SEARCH_URL = process.env.REACT_APP_DATENO_FREE_SEARCH_URL || "https://dateno.io"
const CSRF_COOKIE_NAME = process.env.REACT_APP_CSRF_COOKIE_NAME || "csrftoken"
const MY_DATENO_BASE_URL = process.env.REACT_APP_MY_DATENO_BASE_URL || ""

export const Settings = Object.freeze({
  DATENO_PUBLIC_API_URL: API_URL, // alias
  DATENO_FREE_SEARCH_URL,
  CSRF_COOKIE_NAME,
  MY_DATENO_BASE_URL,
})

export default Settings
