import { Link } from "react-router-dom"
import { ReactComponent as IconGithub } from "../assets/icons/IconGithub.svg"
import { ReactComponent as IconX } from "../assets/icons/IconX.svg"
import { ReactComponent as IconDiscord } from "../assets/icons/IconDiscord.svg"

const SOCIAL_ICONS = Object.freeze({
  github: <IconGithub />,
  twitter: <IconX />,
  discord: <IconDiscord />,
})

export default function FooterItem(props) {
  const cls = "nav-link px-2 link-offset-2"
  const label = props.icon ? `${props.icon} ${props.name}` : props.name
  let atag
  if (props.justtext) {
    atag = <span className={cls}>{label}</span>
  } else if (props.social) {
    atag = (
      <a className={cls} href={props.href}>
        {SOCIAL_ICONS[props.social]}
      </a>
    )
  } else if (props.href) {
    atag = (
      <a className={cls} href={props.href}>
        {label}
      </a>
    )
  } else {
    atag = (
      <Link className={cls} href={props.href}>
        {label}
      </Link>
    )
  }
  return <li className="nav-item">{atag}</li>
}
