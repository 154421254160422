import CurrentSubscriptions from "./CurrentSubscriptions"
import ActivePlans from "./ActivePlans"
import { AlternativeWrap } from "../components/MainWrap"
import { useState, useEffect } from "react"
import { getSubscriptionList } from "../utils/restapi"
import { useUser } from "../auth"
import { useLocation, useNavigate } from "react-router-dom"
import { createSubscription } from "../utils/restapi"

export default function SubscribePage() {
  const [subscriptions, setSubscriptions] = useState([])
  const [error, setError] = useState(null)
  const [showPlans, setShowPlans] = useState(false)
  const [loading, setLoading] = useState(false)
  const search = useLocation().search
  const navigate = useNavigate()
  const user = useUser()

  useEffect(() => {
    const searchParams = new URLSearchParams(search) // upgrade react-router to use useSearchParams hook
    if (searchParams.has("plan")) {
      handleCreateSubscription(parseInt(searchParams.get("plan"), 10))
      searchParams.delete("plan")
      navigate(searchParams.toString(), { replace: true }) // REPLACE, redirect
    } else {
      fetchSubscriptions()
    }
  }, [search, navigate])

  const fetchSubscriptions = async (params) => {
    try {
      const response = await getSubscriptionList()
      setSubscriptions(response)
      setError(null)
      setShowPlans(response.count === 0)
    } catch (err) {
      setError(err)
    }
  }

  const handleCreateSubscription = async (planId) => {
    try {
      setError(null)
      setLoading(true)
      const response = await createSubscription(planId)

      if (response.bitcart_invoice_id && window.bitcart?.showInvoice) {
        window.bitcart.showInvoice(response.bitcart_invoice_id)
      }
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }

  return (
    <AlternativeWrap
      width="auto"
      NoticePanel={showPlans ? <>Choose the plan that fits your needs.</> : <>{user.email}</>}
    >
      {loading && <h3>Crafting an invoice for you</h3>}
      {subscriptions?.count ? (
        <CurrentSubscriptions subscriptions={subscriptions} onSubscriptionsUpdate={fetchSubscriptions} />
      ) : (
        ""
      )}
      {error && (
        <p>
          We will show your subscriptions soon. Please, wait a bit or show it to our support: {JSON.stringify(error)}
        </p>
      )}

      {showPlans && <ActivePlans onSubscriptionsUpdate={fetchSubscriptions} />}
    </AlternativeWrap>
  )
}
