import { useState, useEffect } from "react"
import { syncInvoiceStatus } from "../utils/restapi"

const InvoiceDetails = ({ invoice }) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    setResponse(invoice)
  }, [invoice])

  const updateInvoiceStatus = async (id) => {
    try {
      const response = await syncInvoiceStatus(id)
      setResponse(response)
      setError(null)
    } catch (error) {
      setError("Failed to update invoice ")
    }
  }

  if (!response) {
    return <p className="p-2">No info about invoices yet {JSON.stringify(invoice)}</p>
  }

  return (
    <>
      <p className="m-0 p-2">
        Payment for ${response.amount}{" "}
        {response.status === "pending" ? (
          <a target="_blank" rel="noreferrer" href={response?.bitcart_invoice_url}>
            is {response.status}
          </a>
        ) : (
          <>is {response.status}.</>
        )}{" "}
        {response.status !== "completed" && (
          <button className="btn btn-light border" onClick={() => updateInvoiceStatus(response.bitcart_invoice_id)}>
            Re-check payment status
          </button>
        )}
      </p>
      {error ? <p>Error {JSON.stringify(error)}</p> : ""}
    </>
  )
}

export default InvoiceDetails
