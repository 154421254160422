import { ReactComponent as IconDateno } from "../assets/icons/IconDateno.svg"
// import { Link } from "react-router-dom"
import classes from "./Logo.module.css"

export default function Logo({ title = "My Dateno" }) {
  return (
    <span className={classes["logo-wrapper"]}>
      <IconDateno className={classes["logo"]} />
      <span>{title}</span>
    </span>
  )
}
